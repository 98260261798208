.log-container[data-v-6c6825d3] {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.log-container .log-title[data-v-6c6825d3] {
    width: 100%;
    height: 32px;
    line-height: 32px;
    letter-spacing: 1px;
    font-size: var(--rootFontSize);
    font-weight: 700;
    color: #606266;
    border-bottom: 1px solid #333;
}
.log-container .log-title .process-end[data-v-6c6825d3] {
      letter-spacing: 1px;
      color: red;
      font-size: var(--rootFontSize);
}
.log-container .log-content[data-v-6c6825d3] {
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 10px;
    padding-left: 2px;
    overflow: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.log-container .log-content .log-item-wrapper[data-v-6c6825d3] {
      width: 100%;
      padding-bottom: 10px;
}
.log-container .log-content .log-item-wrapper[data-v-6c6825d3] .el-timeline-item__wrapper {
        padding-left: 20px;
        top: -6px;
}
.log-container .log-content .log-item-wrapper .log-item-title[data-v-6c6825d3] {
        width: 100%;
        height: 28px;
        line-height: 28px;
        letter-spacing: 1px;
        font-size: var(--rootFontSize);
}
.log-container .log-content .log-item-wrapper .log-item-tip[data-v-6c6825d3] {
        height: 18px;
        line-height: 16px;
        letter-spacing: 1px;
        background-color: #ecf5ff;
        display: inline-block;
        padding: 0 10px;
        font-size: var(--rootFontSize);
        color: #f56c6c;
        border: 1px solid #d9ecff;
        border-radius: 4px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        white-space: nowrap;
        cursor: pointer;
}
.log-container .log-content .log-item-wrapper .log-item-content[data-v-6c6825d3] {
        position: relative;
        width: 90%;
        border: 1px dashed #ccc;
        border-radius: 3px;
        padding: 2px 6px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        min-height: 54px;
        margin: 4px 0px;
}
.log-container .log-content .log-item-wrapper .log-item-content .log-item-row[data-v-6c6825d3] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          width: 100%;
}
.log-container .log-content .log-item-wrapper .log-item-content .log-item-row .log-item-row-label[data-v-6c6825d3] {
            width: 80px;
            height: 24px;
            line-height: 24px;
}
.log-container .log-content .log-item-wrapper .log-item-content .log-item-row .log-item-row-value[data-v-6c6825d3] {
            width: calc(100% - 100px);
            height: auto;
            line-height: 24px;
}
.log-container .log-content .log-item-wrapper .log-item-content .log-item-row .log-item-row-value[data-v-6c6825d3] .file-tag-list {
              margin: 0px;
}
.log-container .log-content .log-item-wrapper .log-item-content .log-item-row-value-type[data-v-6c6825d3] {
          width: 60px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background: #eaf4e5;
          color: #80dc58;
}
.log-container .log-content .log-item-wrapper .log-item-content .log-type[data-v-6c6825d3] {
          position: absolute;
          top: calc(50% - 14px);
          left: calc(100% - 30px);
          width: 60px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #eaf4e5;
          color: #80dc58;
}
.el-container[data-v-6c6825d3] {
  border: 0px solid #ccc;
}
.el-container .el-header[data-v-6c6825d3] {
    font-weight: 800;
    font-size: var(--rootFontSize);
}
[data-v-6c6825d3] .el-timeline {
  padding: 0 0 0 0;
  text-align: left;
}
[data-v-6c6825d3] .el-timeline scroll:auto .el-timeline-item__timestamp {
    color: #141415;
    line-height: 1;
    font-size: var(--rootFontSize);
}
